@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700,900|Noto+Serif+JP:200,300,400,500,600,700,900&amp;subset=japanese);
.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 400;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  font-weight: 700;
  letter-spacing: 0.1em;
  color: #333;
  font-style: normal;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 16px;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.inner {
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 0 20px;
}

@media only screen and (max-width: 640px) {
  .inner {
    padding: 20px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .inner {
    padding: 64px 0 20px;
  }
}

.inner2 {
  max-width: 1000px;
  margin: 0 auto;
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
  margin-bottom: 100px;
}

#contents {
  width: 100%;
  max-width: 1200px;
}

@media only screen and (max-width: 834px) {
  #contents {
    margin-bottom: 50px !important;
  }
}

@media only screen and (max-width: 834px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  text-decoration: none;
  transition: 1s;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

.imgR, .imgL {
  max-width: 100% !important;
  float: none;
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.imgL {
  margin: 0;
  float: none;
  max-width: 100% !important;
}

.mincho {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  text-align: center;
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

.spnone {
  display: none;
}

@media only screen and (max-width: 834px) {
  .spnone {
    display: block;
  }
}

.pcnone {
  display: block;
}

@media only screen and (max-width: 834px) {
  .pcnone {
    display: none;
  }
}

.hed_left {
  width: 24%;
}

@media only screen and (max-width: 834px) {
  .hed_left {
    width: 100% !important;
  }
}

.hed_center {
  width: 50%;
}

@media only screen and (max-width: 834px) {
  .hed_center {
    display: none;
  }
}

#header {
  width: 100%;
  background: #fff;
}

#header .hedwrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 10px;
}

@media only screen and (max-width: 640px) {
  #header .hedwrap {
    display: block;
    padding: 0;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #header .hedwrap {
    align-items: center;
    padding: 0;
  }
}

#header #siteID {
  text-align: left;
}

@media only screen and (max-width: 834px) {
  #header #siteID {
    text-align: center;
    padding: 10px;
  }
}

#header #siteID a {
  opacity: 1;
}

#header .hed_right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}

@media only screen and (max-width: 834px) {
  #header .hed_right {
    justify-content: center;
    background: #fff;
    padding: 10px;
    border-top: 5px solid #f58a00;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

#header .hed_right .uketuke {
  text-align: left;
  font-size: 0.8em;
}

#header .tel2 {
  font-size: 1.5em;
  color: #f58a00;
  margin-right: 30px;
}

@media only screen and (max-width: 640px) {
  #header .tel2 {
    margin: 0;
  }
}

#header .tel2 a {
  color: #f58a00;
}

#header .tel2 img {
  margin-right: 10px;
}

#header .tel2 p {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.cont a {
  padding: 15px;
  display: block;
  background: #debb69;
  color: #fff;
  font-size: 1.25em;
}

#hed_info {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  #hed_info {
    width: 100%;
    padding: 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #hed_info {
    display: block;
  }
}

#hed_info .hed_cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  #hed_info .hed_cont {
    padding: 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #hed_info .hed_cont {
    justify-content: flex-end;
  }
}

.hide {
  transform: translateY(-200%);
}

@media only screen and (max-width: 834px) {
  .hide {
    transform: none;
  }
}

#nav_global {
  background: #ede4a6;
  transition: 0.5s ease-in-out;
}

@media only screen and (max-width: 834px) {
  #nav_global {
    padding-top: 50px;
  }
}

#nav_global ul {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

@media only screen and (max-width: 834px) {
  #nav_global ul {
    display: block;
    padding: 30px;
    margin-top: 100px;
  }
}

#nav_global ul li {
  position: relative;
}

@media only screen and (max-width: 834px) {
  #nav_global ul li {
    text-align: left;
  }
}

#nav_global ul li a {
  color: #333;
  display: block;
  padding: 10px;
  font-size: 1rem;
}

#nav_global ul li a:hover {
  opacity: 0.6;
}

#nav_global ul ul {
  position: absolute;
  width: 215px;
  z-index: 100;
  top: 100%;
  left: -44%;
  display: block;
  background: rgba(255, 255, 255, 0.7);
  text-align: center;
}

.mainArea {
  width: 100%;
}

.mainwrap {
  position: relative;
}

.mainwrap .main_txt {
  position: absolute;
  top: 0;
  left: -50%;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 230px;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  text-align: left;
  line-height: 1.5em;
  background: rgba(255, 255, 255, 0.5);
  width: 715px;
  padding: 30px;
  font-size: 50px;
  white-space: nowrap;
}

@media screen and (min-width: 834px) and (max-width: 1380px) {
  .mainwrap .main_txt {
    left: -30%;
  }
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  .mainwrap .main_txt {
    left: 0;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .mainwrap .main_txt {
    left: 0;
  }
}

.mainwrap .main_txt span {
  font-size: 60px;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  .mainwrap .main_txt span {
    font-size: 30px;
  }
}

@media only screen and (max-width: 640px) {
  .mainwrap .main_txt {
    text-align: center;
    height: 130px;
    width: 100%;
    left: 0;
    font-size: 20px;
  }
}

.slick-initialized .slick-slide {
  height: auto;
}

#local-keyvisual {
  position: relative;
  background-position: left top;
}

@media only screen and (max-width: 640px) {
  #local-keyvisual {
    margin-bottom: 30px;
  }
}

#local-keyvisual h1 {
  letter-spacing: 0.1em;
  color: #fff;
  font-size: 3em;
  font-weight: 700;
  z-index: 1;
  position: relative;
  text-align: left;
  line-height: 1.3em;
  max-width: 1200px;
  margin: 0 auto;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #local-keyvisual h1 {
    font-size: 2.5em;
    padding: 50px 0;
    text-align: center;
  }
}

@media only screen and (max-width: 640px) {
  #local-keyvisual h1 {
    font-size: 1.7em;
    padding: 50px 0;
    text-align: center;
  }
}

#local-keyvisual h1 span {
  display: block;
  padding: 50px 10px;
}

@media only screen and (max-width: 834px) {
  #local-keyvisual h1 span {
    padding: 0;
  }
}

#local-keyvisual h1 span:empty {
  display: none;
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.pan1 {
  margin: 20px 0 60px 0;
}

@media only screen and (max-width: 640px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #000;
}

.drawer-hamburger {
  z-index: 101 !important;
}

.drawer-hamburger span {
  background: #333 !important;
}

.drawer-hamburger span:before, .drawer-hamburger span:after {
  background: #333 !important;
}

.drawer-hamburger.change-color span {
  background: #2e4121 !important;
}

.drawer-hamburger.change-color span:before, .drawer-hamburger.change-color span:after {
  background: #2e4121 !important;
}

.drawer-nav {
  z-index: 101 !important;
}

.drawer-nav ul li a {
  display: block;
  padding: 1em 0;
  color: #fff;
  font-size: 20px;
}

.drawer-nav ul li {
  border-bottom: 1px solid #fff;
}

.drawer-hamburger {
  z-index: 201 !important;
}

.drawer-overlay {
  z-index: 201 !important;
}

.drawer-nav {
  z-index: 202 !important;
}

.drawer-open .drawer-hamburger .drawer-hamburger-icon {
  background-color: transparent !important;
}

.drawer-nav ul li a {
  display: block;
  padding: 1em 0;
  color: #fff;
  font-size: 18px;
}

@media only screen and (max-width: 834px) {
  .for-pc {
    display: none !important;
  }
}

.not-for-pc {
  display: none !important;
}

@media only screen and (max-width: 834px) {
  .not-for-pc {
    display: block !important;
  }
}

@media only screen and (max-width: 640px) {
  .not-for-sp {
    display: none !important;
  }
}

.fixed {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  text-align: center;
}

footer#global_footer .inner {
  padding: 50px 0;
}

@media only screen and (max-width: 834px) {
  footer#global_footer .inner {
    padding: 30px 0;
  }
}

footer#global_footer a {
  text-decoration: none;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

@media only screen and (max-width: 640px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

footer#global_footer #nav_footer ul {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

footer#global_footer #nav_footer li a {
  text-decoration: none;
  padding: 10px;
  display: block;
  color: #333;
  text-align: center;
}

footer#global_footer #nav_footer li a:hover {
  opacity: 0.6;
}

footer#global_footer #copy a {
  text-decoration: none;
  color: #333;
}

footer#global_footer #copy a:hover {
  opacity: 0.6;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  footer#global_footer #copy a {
    line-height: 60px;
  }
}

.ftlogo {
  margin-bottom: 50px;
}

.footer2 {
  padding: 60px 0;
  background: url(../images/common/cont_bg.jpg) center;
  background-size: cover;
}

.footer2 .ft_h2 {
  margin-bottom: 50px;
}

.footer2 .ft_h2 h2 {
  font-size: 1.8em;
  font-weight: bold;
  position: relative;
}

.footer2 .ft_h2 h2:before {
  content: '';
  position: absolute;
  bottom: -15px;
  display: inline-block;
  width: 230px;
  height: 1px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #ede4a6;
  border-radius: 2px;
}

.footer2 .ft_h2 h2 span {
  display: block;
  color: #d1b47e;
  font-size: 13px;
  font-weight: 400;
}

.footer2 .ft_txt {
  margin-bottom: 50px;
}

.footer2 .ftwrap {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

@media only screen and (max-width: 640px) {
  .footer2 .ftwrap {
    display: block;
    padding: 10px;
  }
}

.footer2 .ftwrap .tel2 {
  color: #fff;
  background: #f58a00;
  font-size: 1.6em;
  padding: 15px;
  margin-right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

@media only screen and (max-width: 640px) {
  .footer2 .ftwrap .tel2 {
    margin-right: 0;
  }
}

.footer2 .ftwrap .tel2 img {
  margin-right: 15px;
}

.footer2 .ftwrap .tel2 a {
  color: #fff;
}

.footer2 .ftwrap .form a {
  color: #fff;
  background: #debb69;
  font-size: 1.25em;
  padding: 18px;
  display: block;
}

@media only screen and (max-width: 640px) {
  .footer2 .ftwrap .form a {
    margin-top: 15px;
  }
}

.pagetop {
  position: fixed;
  right: 10px;
  bottom: 50px;
  z-index: 2;
}

@media only screen and (max-width: 834px) {
  .pagetop {
    bottom: 13%;
  }
}

.pagetop a {
  padding: 15px;
  background: #ede4a6;
  border-radius: 50%;
}

.pagetop a i {
  color: #fff;
  font-size: 1.5em;
}

@media only screen and (max-width: 834px) {
  #footer {
    display: block;
    text-align: center;
  }
}

.ft_logo {
  text-align: center;
  color: #fff;
}

@media only screen and (max-width: 834px) {
  .ft_logo {
    width: 100%;
    text-align: center;
  }
}

.ft_logo ul li:nth-of-type(1) {
  margin-bottom: 30px;
}

.small_bg {
  background: #ede4a6;
  color: #333;
}

/* box */
.h2_01 h2 {
  font-size: 2.1em;
  color: #333;
  line-height: 1.2em;
  font-weight: bold;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

@media only screen and (max-width: 834px) {
  .h2_01 h2 {
    font-size: 24px;
  }
}

.h2_02 h2 {
  font-size: 1.8em;
  color: #333;
  line-height: 1.2em;
  font-weight: bold;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .h2_02 h2 {
    font-size: 24px;
  }
}

.h2_02 h2:before, .h2_02 h2:after {
  content: "";
  flex-grow: 1;
  border-top: 4px dotted #ede4a6;
  display: block;
}

.h2_02 h2:before {
  margin-right: .4em;
}

.h2_02 h2:after {
  margin-left: .4em;
}

.h2_03 h2 {
  font-size: 1.8em;
  color: #333;
  line-height: 1.2em;
  font-weight: bold;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  border-bottom: 1px solid #ede4a6;
}

.h2_03 h2 span {
  font-size: 13px;
  color: #d1b47e;
}

.h2_04 h2 {
  font-size: 1.8em;
  color: #333;
  line-height: 1.2em;
  font-weight: bold;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  position: relative;
}

.h2_04 h2:before {
  content: '';
  position: absolute;
  bottom: -15px;
  display: inline-block;
  width: 200px;
  height: 1px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #ede4a6;
  border-radius: 2px;
}

.h2_04 h2 span {
  font-size: 13px;
  color: #d1b47e;
  display: block;
}

@keyframes horizontal {
  0% {
    transform: translateX(-3px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes vertical {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.boxwrap {
  position: absolute;
  z-index: 2;
  position: absolute;
  bottom: 2%;
  left: 0;
  right: 0;
}

@media only screen and (max-width: 640px) {
  .boxwrap {
    bottom: 10%;
  }
}

.txt > div {
  line-height: 2em;
}

.con100 {
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

.newswrap .news {
  height: 260px;
  overflow-y: auto;
  max-width: 1000px;
  margin: 0 auto;
}

.newswrap .news dt {
  border: none !important;
  color: #333;
}

@media only screen and (max-width: 834px) {
  .newswrap .news dt {
    width: 100% !important;
    display: block !important;
  }
}

.newswrap .news dd {
  border: none !important;
  color: #333;
  width: 100% !important;
}

@media only screen and (max-width: 834px) {
  .newswrap .news dd {
    width: 100% !important;
  }
}

.newswrap .news dl {
  border-bottom: 1px dashed #ede4a6;
  padding: 15px 0 !important;
}

@media only screen and (max-width: 834px) {
  .newswrap .news dl {
    display: block !important;
    width: 100% !important;
  }
}

.btn {
  width: 290px;
  margin: 0 auto;
}

.btn a {
  color: #fff;
  background: #f58a00;
  padding: 15px;
  display: block;
  font-size: 1.3em;
  white-space: nowrap;
  font-weight: 500;
}

.btn a i {
  margin-left: 30px;
}

.btn2 {
  width: 290px;
}

@media only screen and (max-width: 834px) {
  .btn2 {
    width: 100%;
  }
}

.btn2 a {
  color: #fff;
  background: #f58a00;
  padding: 15px;
  display: block;
  font-size: 1.3em;
  white-space: nowrap;
  font-weight: 500;
}

.btn2 a i {
  margin-left: 30px;
}

.sec_01 {
  background: url(../images/common/cont1_bg.png) center;
  background-size: cover;
  position: relative;
}

.sec_02 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 834px) {
  .sec_02 {
    display: block;
  }
}

.sec_02 .table_img {
  width: 31%;
}

@media only screen and (max-width: 834px) {
  .sec_02 .table_img {
    width: 100%;
  }
}

.sec_02 .table_img td {
  border: none !important;
}

.sec_02 .table_img .img {
  padding: 0 !important;
}

.sec_02 .table_img .txt {
  text-align: left;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .sec_02 .table_img .txt {
    text-align: center !important;
  }
}

.sec_02 .table_img .title {
  background: #ede4a6;
  font-size: 1.25em;
  font-weight: 500;
}

.sec_02 .table_img .title div {
  font-weight: 500;
}

.sec_03 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 640px) {
  .sec_03 {
    display: block;
  }
  .sec_03 .flxL {
    width: 100% !important;
  }
  .sec_03 .flxR {
    width: 100% !important;
  }
}

.sec_03 .flxL {
  width: 58%;
}

@media only screen and (max-width: 640px) {
  .sec_03 .flxL {
    margin-right: 0;
    width: 100%;
    text-align: center !important;
  }
}

.sec_03 .flxR {
  width: 36%;
}

@media only screen and (max-width: 640px) {
  .sec_03 .flxR {
    width: 100%;
    margin-top: 15px;
  }
}

.sec_04 {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .sec_04 {
    display: block;
  }
  .sec_04 .flxL {
    width: 100% !important;
  }
  .sec_04 .flxR {
    width: 100% !important;
  }
}

.sec_04 .flxL {
  width: 58%;
}

@media only screen and (max-width: 640px) {
  .sec_04 .flxL {
    margin-right: 0;
    width: 100%;
    text-align: center !important;
  }
}

.sec_04 .flxR {
  width: 36%;
}

@media only screen and (max-width: 640px) {
  .sec_04 .flxR {
    width: 100%;
    margin-top: 15px;
  }
}

.bnrbox {
  justify-content: space-between;
}

.bnrbox .box {
  text-align: center;
  width: 46%;
  margin: 10px;
  position: relative;
  border: 1px solid #fff;
  box-shadow: 0 0 0 5px #ede4a6;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .bnrbox .box img {
    width: 100% !important;
  }
}

@media only screen and (max-width: 834px) {
  .bnrbox .box {
    width: 100%;
  }
}

.bnrbox .box a {
  display: block;
}

.bnrbox .box .kakomi {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  max-width: 516px;
  height: 188px;
  color: #fff;
  padding: 24px;
  margin: auto;
}

@media only screen and (max-width: 640px) {
  .bnrbox .box .kakomi {
    margin: 0;
    padding: 50px;
    height: 100%;
  }
}

.bnrbox .box .kakomi span {
  display: block;
  font-size: 1.6em;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  margin-bottom: 15px;
}

@media only screen and (max-width: 834px) {
  .bnrbox .box .kakomi {
    width: 100%;
    max-width: 100%;
  }
}

.bnrbox .box h3 {
  display: none;
}

@media only screen and (max-width: 640px) {
  .bnrbox .box {
    width: 100%;
    margin-top: 20px;
  }
}

.bnrbox2 {
  justify-content: space-between;
}

.bnrbox2 .box {
  text-align: center;
  width: 46%;
  margin: 10px;
  position: relative;
  border: 1px solid #fff;
  box-shadow: 0 0 0 5px #ede4a6;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .bnrbox2 .box img {
    width: 100% !important;
  }
}

@media only screen and (max-width: 834px) {
  .bnrbox2 .box {
    width: 100%;
  }
}

.bnrbox2 .box span {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  max-width: 516px;
  height: 110px;
  color: #fff;
  padding: 40px;
  margin: auto;
  display: block;
  font-size: 1.6em;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

@media only screen and (max-width: 640px) {
  .bnrbox2 .box span {
    margin: 0;
    padding: 50px;
    height: 100%;
  }
}

@media only screen and (max-width: 834px) {
  .bnrbox2 .box span {
    width: 100%;
    max-width: 100%;
  }
}

.bnrbox2 .box h3 {
  display: none;
}

@media only screen and (max-width: 640px) {
  .bnrbox2 .box {
    width: 100%;
    margin-top: 20px;
  }
}

.h2_05 h2 {
  font-size: 1.8em;
  color: #333;
  line-height: 1.2em;
  font-weight: bold;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  background: #fafad2;
  padding: 10px;
  position: relative;
  padding-left: 25px;
}

.h2_05 h2:before {
  content: "";
  width: 5px;
  height: 10px;
  border-radius: 500px;
  background: #f58a00;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
}

.h3_01 h3 {
  font-size: 1.25em;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  background: #f58a00;
  color: #fff;
  border-radius: 5px;
  padding: 6px;
  width: 150px;
}

.h3_01 div {
  position: relative;
}

.h3_01 div:after {
  content: '';
  position: absolute;
  top: 50%;
  display: inline-block;
  width: 82%;
  height: 1px;
  background-color: #ede4a6;
  right: 0;
  right: 0;
}

@media screen and (min-width: 834px) and (max-width: 1000px) {
  .h3_01 div:after {
    width: 50%;
  }
}

@media only screen and (max-width: 834px) {
  .h3_01 div:after {
    width: 50%;
  }
}

.table_gaiyou dt {
  background: #FAFAD2 !important;
}

@media only screen and (max-width: 640px) {
  .table_gaiyou dt {
    display: block !important;
    width: 100% !important;
  }
}

@media only screen and (max-width: 640px) {
  .table_gaiyou dd {
    width: 100% !important;
  }
}

@media only screen and (max-width: 640px) {
  .table_gaiyou dl {
    display: block !important;
  }
}

.table_gaiyou a {
  color: #333;
}

.table_enkaku dt {
  border: none !important;
  border-bottom: 1px solid #f58a00 !important;
  padding: 8px !important;
}

@media only screen and (max-width: 640px) {
  .table_enkaku dt {
    display: block !important;
    width: 100% !important;
  }
}

.table_enkaku dd {
  border: none !important;
  border-bottom: 1px solid #ede4a6 !important;
  padding: 8px !important;
}

@media only screen and (max-width: 640px) {
  .table_enkaku dd {
    width: 100% !important;
  }
}

.table_enkaku dl {
  padding: 0 !important;
}

@media only screen and (max-width: 640px) {
  .table_enkaku dl {
    display: block !important;
  }
}

.table_rei {
  max-width: 800px;
  margin: 0 auto;
}

.table_rei dt {
  border: none !important;
  border-bottom: 1px solid #f58a00 !important;
  padding: 8px !important;
  display: block !important;
  text-align: center;
}

@media only screen and (max-width: 640px) {
  .table_rei dt {
    width: 100% !important;
  }
}

.table_rei dd {
  text-align: center;
  border: none !important;
  border-bottom: 1px solid #ede4a6 !important;
  padding: 8px !important;
}

@media only screen and (max-width: 640px) {
  .table_rei dd {
    width: 100% !important;
  }
}

.table_rei dl {
  padding: 0 !important;
}

@media only screen and (max-width: 640px) {
  .table_rei dl {
    display: block !important;
  }
}

.table_car .title {
  background: #f58a00;
  color: #fff;
}

.table_car .sub {
  background: #FAFAD2;
}

.table_flow {
  background: #F3F3F3;
}

.table_flow td {
  border: none !important;
}

.ancbtn td {
  border: none !important;
}

.txt01 span {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 1.5em;
}

.sec_05 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 640px) {
  .sec_05 {
    display: block;
  }
  .sec_05 .img {
    width: 100% !important;
  }
  .sec_05 .txt {
    width: 100% !important;
  }
}

.sec_05 .img {
  width: 48%;
}

.sec_05 .txt {
  width: 48%;
}

.imgbox {
  justify-content: center;
}

.imgbox .box {
  width: 46%;
  text-align: center;
  padding: 15px;
  margin: 10px;
  background: #FAFAD2;
}

.imgbox .box img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media only screen and (max-width: 640px) {
  .imgbox .box {
    width: 100%;
    margin: 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .imgbox .box {
    width: 47%;
  }
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  .imgbox .box:nth-of-type(1) {
    margin-bottom: 50px;
  }
}

.imgbox .box h3 {
  text-align: center;
  color: #333;
  padding: 10px;
  font-weight: bold;
  font-size: 1.25em;
  border-bottom: 1px solid #f58a00;
}

@media only screen and (max-width: 640px) {
  .imgbox .box article {
    width: 100%;
  }
}

.imgbox .box article > div {
  text-align: center;
  color: #333;
  margin-top: 20px;
}

.imgbox2 {
  justify-content: center;
}

.imgbox2 .box {
  width: 30%;
  margin: 10px;
}

.imgbox2 .box img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.imgbox2 .box h3 {
  display: none;
}

@media only screen and (max-width: 640px) {
  .imgbox2 .box {
    width: 100%;
  }
}

.imgbox2 .box div {
  text-align: center;
  font-weight: bold;
  font-size: 1.25em;
}

.list {
  background: #f3f3f3;
  padding: 15px;
}

.list li {
  float: none !important;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 640px) {
  .list li {
    font-size: 1em !important;
  }
}

.gmap iframe {
  width: 100%;
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 2px solid #f58a00;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 640px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.policy {
  padding: 10px 0;
  text-align: center;
}

.policy a {
  color: #f58a00;
}

.translate {
  display: none;
}
